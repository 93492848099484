.map-holder {
    height: 50vh;
    max-width: 1500px;
    width: 100%;
    margin: 2rem auto;
    padding: 0.3rem 1rem;
  }

  .map-container {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    transition:  all 0.3s ease-in-out;
  }
  .map-container:hover {
    width: 100%;
    height: 100%;
    opacity: 1;
  }


  @media (width <=768px) {
    .map-holder {
      height: 30vh;
      
      padding: 1rem 1rem;
    }
  
  }