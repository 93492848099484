.navigation {
  position: fixed;
  top: 0;
  margin-top: 20px;
  z-index: 100;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out; /* Default transition for showing when scrolling up */
}

.navigation-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  padding-inline: 1.3rem;
  gap: 2rem;
  flex-shrink: 0;
}
.brand-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-basis: 25%;
}
.brand-cart > * {
  object-fit: contain;
}

/* Brand name link */
.brand-name {
  text-decoration: none;
  color: #010101;
  font-size: 1rem;
  margin-left: 1rem;
  font-style: normal;
  line-height: 106%; /* 1.325rem */
  letter-spacing: -0.02em;
}

/* Navigation menu container */
.navigation-menu {
  flex: 1;
  max-width: 800px;
}

/* Navigation menu items */
.navigation-menu .list {
  display: flex;
  padding-left: 0;
  justify-content: space-between;
  align-items: center;
}

/* Navigation menu list items */
.navigation-menu li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
li {
  user-select: none;
}

ul {
  user-select: none;
}
li ul a:active {
  background-color: #ffbf69; /* Add your desired blue color here */
  color: white; /* Text color for better contrast */
}

li ul a:hover {
  background-color: #ffbf69; /* Add your desired hover color here */
  color: white; /* Text color for better contrast */
}
/* Navigation menu links */
.navigation-menu li a {
  text-decoration: none;
  font-weight: 500;
  display: block;
  color: #000;
  width: 100%;
  font-size: 1rem;
  font-style: normal;
}
.navigation-button {
  display: block;
  border-radius: 0.3125rem;
  border: 2px solid #7591a3;
  background: #7591a3;
  color: white;
}
.navigation-button:hover {
  background: #99bdd2;
  color: white;
  border: 2px solid #99bdd2;
}
.navigation-button button {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  line-height: 106%; /* 1.325rem */
  letter-spacing: -0.02em;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
}
/* Hamburger menu icon for small screens */
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: rgba(255, 191, 105, 0.871);
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.sticky {
  margin-top: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  opacity: 0.95;
}

/* Hover effect for hamburger menu icon */
.hamburger:hover {
  background-color: #ffbf69fc;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  /* Display hamburger menu icon */
  .hamburger {
    display: block;
    width: 40px;
  }
  .navigation {
    margin-top: 0px;
  }

  /* Style for expanded navigation menu on small screens */
  .navigation-menu .list {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 17px);
    background-color: white;
    border-top: 1px solid black;
    display: none;
  }
  .navigation-items .brand-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    width: 100%;
    padding-inline: 1.3rem;
  }
  /* Style for expanded navigation menu on small screens */

  .brand-name {
    text-decoration: none;
    color: #010101;
    font-size: 14px;
    font-family: Poppins;
    margin-left: 1rem;
    font-weight: 600;
    font-style: normal;
    line-height: 106%; /* 1.325rem */
    letter-spacing: -0.02em;
    display: none;
  }
  .brand-cart {
    flex: 1;
    width: fit-content;
    padding: 0;
  }
  /* Display expanded navigation menu */
  .navigation-menu.expanded ul {
    display: block;
  }

  /* Style for navigation menu list items on small screens */
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  /* Style for navigation menu links on small screens */
  .navigation-menu li .item {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li a {
    color: rgb(255, 254, 254);
    width: 100%;
    padding: 1.5rem 0;
  }

  /* Hover effect for navigation menu list items on small screens */
}

/* Hidden navbar styles for smooth transitions */

@media screen and (max-width: 1024px) {
  .navigation-button {
    display: none;
  }
}

@media (width >768px) {
  .hidden-navbar {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Transition both transform and opacity */
  }
}
