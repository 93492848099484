.about-us-header {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/csm_Wohnsituation_easyLight_frame_RGB_fuer_Web_48da166267.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 16px solid #FFBF69;
  }
  
  .about-us-header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    background-color: #000101;

    width: 100%;
    height: 100%;
    border: 2px solid;
  }
  

.about-us-header p {
  font-size: 68px;
  color: #ffffff;
  font-weight: 600;
  z-index: 10;
}

.about-us-navigator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 2.2rem;
  font-size: 22px;
  color: #244574;
  border-bottom: 1px solid #eeeeee;
}

.about-us-navigator span {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  margin: 0 3px;
  font-weight: normal;
  font-family: "Lato", sans-serif;
}

.about-us-history {
  background-color: #fafcff;
  margin: 0 auto;
  padding-top: 2rem;
}

.about-us-history-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1150px;
  margin: 0 auto;
  padding: 1rem 0.5rem;
  height: 100%;
  background-color: #fafcff;
  gap: 1rem;
}

.about-us-history-container .history-img {
  width: 100%;
  padding: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.history-img img {
  width: 100%;
  max-width: 510px;
  height: 600px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow effect */
  border-radius: 4px;
}

.history-text {
  flex: 1;

  width: 100%;
  height: 100%;
  min-height: 600px;
  max-width: 510px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start ;
}

.history-text h3 {
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  color: #244574;
}

.history-text p {
  font-size: 18px;
  line-height: 30px;
  color: #798598;
  font-weight: normal;
  display: block;

  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.about-us-goal-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  max-width: 1150px;
  margin: 5rem auto;
  padding: 1rem 0.5rem;
  height: 100%;
  gap: 1rem;
}

.about-us-goal-container .history-img {
  width: 100%;
  padding: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.about-us-goal-container .history-img img {
  position: absolute;
  bottom: -140px;
  object-fit: cover;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow effect */
  border-radius: 4px;
}



.about-us-contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 1rem;

}
.about-us-contact-container >*{
    margin-bottom: 0.5rem;

}
.about-us-contact-container h1{
    font-size: 35px;
    line-height: 47px;
    color: #233D63;
    padding-bottom: 35px;
    text-align: center;
}
.contact-us-link{
    width: 100%;
    max-width: 300px;
    padding: 10px;
}

@media (width <= 1024px) {
  .about-us-history-container {
    flex-direction: column;
  }
  .about-us-history-container .history-img {
    justify-content: center;
  }
  .about-us-history-container .history-img img {
    width: 80%;
    max-width: 100%;
    height: 100%;
    aspect-ratio: 4/5;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow effect */
    border-radius: 4px;
  }
  .history-text {
    width: 80%;
    flex: auto;
    min-height: unset;
  }

  .about-us-goal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1150px;
    margin: 2rem auto;
    padding: 1rem 0.5rem;
    height: 100%;
    gap: 1rem;
  }

  .about-us-goal-container .history-img {
    justify-content: center;
  }

  .about-us-goal-container .history-img img {
    width: 80%;
    max-width: 100%;
    height: 100%;
    aspect-ratio: 4/5;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow effect */
    border-radius: 4px;
    position: unset;
  }
}
